import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const Cash = loadable(() => import("./Cash"));

export const Order = loadable(() => import("./order/Order"));
export const AppOrder = loadable(() => import("./order/App"));

export const SettingOrder = loadable(() => import("./setting/Order"));
export const SettingOrderDetail = loadable(
  () => import("./setting/OrderDetail")
);
export const SettingCash = loadable(() => import("./setting/Cash"));
export const SettingPassword = loadable(() => import("./setting/Password"));
export const SettingService = loadable(() => import("./setting/Service"));
