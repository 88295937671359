import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { loadingModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

const LoadingModal = () => {
  const [modal, setModal] = useRecoilState(loadingModalState);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper>
      <Wrapper>
        <Loading>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Loading>
        <p>
          잠시만
          <br />
          기다려주세요.
        </p>
      </Wrapper>
    </ModalWrapper>
  );
};

export default LoadingModal;

const Loading = styled.div`
  color: official;
  display: inline-block;
  position: relative;
  width: 46px;
  height: 46px;

  & div {
    transform-origin: 23px 23px;
    animation: lds-spinner 0.9s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 2.06px;
      left: 21px;
      width: 4.18px;
      height: 12px;
      border-radius: 4px;
      background: ${(props) => props.theme.color.main};
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.8s;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.7s;
    }
    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.6s;
    }
    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.5s;
    }
    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.4s;
    }
    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.3s;
    }
    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -0.1s;
    }
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Wrapper = styled.div`
  min-width: 100px;
  width: 100px;
  padding: 18px 15px 16px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin-top: 14px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16.5px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
  }
`;
