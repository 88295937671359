import React from "react";
import { RouteType } from "../../types/types";
import styled from "styled-components";
import DiscountModal from "../modal/DiscountModal";
import PaymentModal from "../modal/PaymentModal";
import AlertModal from "../modal/AlertModal";
import TwoButtonModal from "../modal/TwoButtonModal";
import CashBillModal from "../modal/CashBillModal";
import CashBillCancleModal from "../modal/CashBillCancleModal";
import LoadingModal from "../modal/LoadingModal";
import CashModal from "../modal/CashModal";
import CardSignModal from "../modal/CardSignModal";
import CancleSignModal from "../modal/CancleSignModal";
import PaymentDoneModal from "../modal/PaymentDoneModal";
import PaymentCancelModal from "../modal/PaymentCancelModal";

const WrapperRoute = ({ element, ...props }: RouteType) => {
  return (
    <>
      <Wrapper>{element}</Wrapper>

      <DiscountModal />
      <PaymentModal />
      <CashBillModal />
      <CashBillCancleModal />
      <CashModal />
      <CardSignModal />
      <CancleSignModal />

      <AlertModal />
      <TwoButtonModal />
      <PaymentDoneModal />
      <PaymentCancelModal />

      <LoadingModal />
    </>
  );
};

export default WrapperRoute;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  background: ${(props) => props.theme.color.bg};
`;
