import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AppOrder,
  Cash,
  Order,
  Root,
  SettingCash,
  SettingOrder,
  SettingOrderDetail,
  SettingPassword,
  SettingService,
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import theme from "../utils/theme";
import WrapperRoute from "../components/routes/WrapperRoute";
import HelmetComponent from "../components/HelmetComponent";

const App = () => {
  return (
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WrapperRoute element={<Root />} />} />
            <Route path="/cash" element={<WrapperRoute element={<Cash />} />} />
            <Route
              path="/order"
              element={<WrapperRoute element={<Order />} />}
            />
            <Route
              path="/app/order"
              element={<WrapperRoute element={<AppOrder />} />}
            />
            <Route
              path="/setting/order"
              element={<WrapperRoute element={<SettingOrder />} />}
            />
            <Route
              path="/setting/order/:id"
              element={<WrapperRoute element={<SettingOrderDetail />} />}
            />
            <Route
              path="/setting/cash"
              element={<WrapperRoute element={<SettingCash />} />}
            />
            <Route
              path="/setting/password"
              element={<WrapperRoute element={<SettingPassword />} />}
            />
            <Route
              path="/setting/service"
              element={<WrapperRoute element={<SettingService />} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
