import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  PaymentModalState,
  alertModalState,
  cashBillModalState, paymentDoneModalState, cardSignModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import {api} from "../../utils/api";
import Input from "../elements/Input";

const PaymentModal = () => {
  const [carNumber, setCarNumber] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const [modal, setModal] = useRecoilState(PaymentModalState);
  const setCashBillModal = useSetRecoilState(cashBillModalState);
  const setDoneModal = useSetRecoilState(paymentDoneModalState);
  const setSignModal = useSetRecoilState(cardSignModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handlePayment = async () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("discount_price", modal?.discount?.toString());
    // @ts-ignore
    JSON.parse(localStorage.getItem("order"))?.map((menu, i) => {
        formData.append(`pos_product_ids[${i}][id]`, menu?.id);
        formData.append(`pos_product_ids[${i}][amount]`, menu?.amount);
    });
    formData.append("name", name);
    formData.append("car_number", carNumber);
    formData.append("phone", phone);

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/order", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      if (data2?.payment_price === 0) {
        window.paymentPopup(true, "")
      } else {
        if (data2?.payment_price >= 50000) {
          setSignModal({
            onDone: () => {
              window.rayno.payment(localStorage.getItem("token"), data2?.id, localStorage.getItem('installment'), "");
              localStorage.removeItem('installment')
              //localStorage.removeItem('signUrl')
            },
          });
        } else {
          window.rayno.payment(localStorage.getItem("token"), data2?.id, "00", "")
        }
      }
    } else {
      window.alert(alert);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>결제자 정보 입력</Title>
        <PasswordArea>
          <Input
              style={{ background: "#464650" }}
              placeholder="차량번호 입력"
              value={carNumber}
              onChange={(e) => setCarNumber(e.target.value)}
          />
          <Input
              style={{ background: "#464650" }}
              placeholder="고객 성함"
              value={name}
              onChange={(e) => setName(e.target.value)}
          />
          <Input
              style={{ background: "#464650" }}
              placeholder="연락처"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
          />
        </PasswordArea>
        <button style={{ textDecoration: "underline", width: "100%", margin: "0 auto", color: "#FFFFFF99"}} onClick={() => handlePayment() }>건너뛰기</button>
        <div>
          <Button style={{ width: "43%", margin: "15px 10px 0 10px" }} styleType="line" onClick={handleClose}>
            취소
          </Button>
          <Button style={{ width: "43%", margin: "15px 10px 0 0", background: "#FFFFFFE5", color: "#0B0B0C" }} styleType="line" onClick={() => handlePayment()}>
            다음
          </Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PaymentModal;

const PasswordArea = styled.div`
  width: 220px;
  margin: auto;
  margin-bottom: 20px;

  & > label:nth-of-type(n + 2) {
    display: block;
    margin-top: 12px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: white;

    & > span {
      font-size: 10px;
      line-height: 15px;
      margin-left: 2px;
    }
  }
`;

const Seperate = styled.div`
  margin: 30px 0 16px;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.line};
`;

const TypeBox = styled.div`
  width: calc(50% - 5px);
  border-radius: 14px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  padding: 25px 10px 18px;

  & > svg {
    width: 30px;
    height: 30px;
    margin: 0 auto 14px;
    display: block;
  }

  & > p {
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const TypeArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  min-width: 284px;
  width: 284px;
  padding: 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
