import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";

interface ModalWrapperProps {
  children: ReactNode | ReactNode[];
  onClose?: () => void;
}

function ModalWrapper({ children, onClose }: ModalWrapperProps) {
  const handleClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (e.currentTarget !== e.target) return;

    onClose?.();
  };

  useEffect(() => {
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, []);

  return <Wrapper onClick={handleClose}>{children}</Wrapper>;
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 27, 0.7);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ModalWrapper;
