import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cashBillModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import Input from "../elements/Input";
import { toNumber } from "../../utils/CommonFunction";

import { ReactComponent as Remove } from "../../assets/icons/delete-icon.svg";

const CashBillModal = () => {
  const [modal, setModal] = useRecoilState(cashBillModalState);

  const [phone, setPhone] = useState<string>("");

  const handleClose = () => {
    setPhone("");
    setModal(null);
  };

  const handleSubmit = () => {
    setPhone("");
    modal?.onDone && modal?.onDone();
    setModal(null);
  };

  const handleNone = () => {
    setPhone("");
    modal?.onDone && modal?.onDone();
    setModal(null);
  };

  const handleClick = (value: number) => {
    setPhone(phone + value);
  };

  const handleRemove = () => {
    if (phone.length > 0) setPhone(phone.slice(0, phone.length - 1));
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>현금 영수증 발급</Title>
        <Input
          className="light"
          placeholder="휴대폰번호 또는 사업자번호"
          value={phone}
          onChange={(e) => setPhone(toNumber(e.target.value))}
        />
        <DialArea>
          <Dial onClick={() => handleClick(1)}>1</Dial>
          <Dial onClick={() => handleClick(2)}>2</Dial>
          <Dial onClick={() => handleClick(3)}>3</Dial>
          <Dial onClick={() => handleClick(4)}>4</Dial>
          <Dial onClick={() => handleClick(5)}>5</Dial>
          <Dial onClick={() => handleClick(6)}>6</Dial>
          <Dial onClick={() => handleClick(7)}>7</Dial>
          <Dial onClick={() => handleClick(8)}>8</Dial>
          <Dial onClick={() => handleClick(9)}>9</Dial>
          <Dial onClick={handleRemove}>
            <Remove />
          </Dial>
          <Dial onClick={() => handleClick(0)}>0</Dial>
        </DialArea>
        <CancleButton type="button" onClick={handleClose}>
          결제 취소
        </CancleButton>
        <ButtonArea>
          <Button styleType="line" onClick={handleNone}>
            미발행
          </Button>
          <Button disabled={phone === ""} onClick={handleSubmit}>
            현금 영수증 발행
          </Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CashBillModal;

const CancleButton = styled.button`
  margin: 16px auto 30px;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  text-decoration: underline;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    max-width: calc(50% - 5px);
  }
`;

const Dial = styled.div`
  width: calc(33.33% - 13.33px);
  height: 50px;
  margin-right: 20px;
  border-radius: 10px;
  background: rgba(34, 34, 37, 0.4);
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.06em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 32px;
    height: 24px;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: 4px;
  }
`;

const DialArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  min-width: 260px;
  width: 260px;
  padding: 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
