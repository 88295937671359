import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cardSignModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import Sign from "../elements/Sign";

import { ReactComponent as Arrow } from "../../assets/icons/select-arrow-icon.svg";

const CardSignModal = () => {
  //const signCanvas = useRef<any>(null);
  const [modal, setModal] = useRecoilState(cardSignModalState);

  const [selectInstallment, setSelectInstallment] = useState(0);

  const handleClose = () => {
    setModal(null);
  };

  const handleSubmit = () => {
    localStorage.setItem('installment', selectInstallment?.toString().padStart(2, "0"))
    //localStorage.setItem('signUrl', signCanvas.current.toDataURL('image/png'))
    modal?.onDone && modal?.onDone();
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>신용/체크카드 결제 서명</Title>
        <SelectArea>
          <Select onChange={(e) => { setSelectInstallment(Number(e.target.value)) }}>
            <option value={0}>일시불</option>
            <option value={2}>2개월</option>
            <option value={3}>3개월</option>
            <option value={4}>4개월</option>
            <option value={5}>5개월</option>
            <option value={6}>6개월</option>
            <option value={7}>7개월</option>
            <option value={8}>8개월</option>
            <option value={9}>9개월</option>
            <option value={10}>10개월</option>
            <option value={11}>11개월</option>
            <option value={12}>12개월</option>
          </Select>
          <Arrow />
        </SelectArea>
        {/*<Separate />
        <Sign signCanvas={signCanvas} />*/}
        <ButtonArea>
          <Button styleType="line" onClick={handleClose}>
            취소
          </Button>
          <Button onClick={handleSubmit}>다음</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CardSignModal;

const Select = styled.select`
  width: 100%;
  height: 44px;
  background: ${(props) => props.theme.color.gray4};
  border-radius: 10px;
  color: white;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  appearance: none;
`;

const SelectArea = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    width: 11px;
    height: 6px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Separate = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.line};
  margin: 16px 0;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  & > button {
    max-width: calc(50% - 5px);
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  min-width: 284px;
  width: 284px;
  padding: 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
