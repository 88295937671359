import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cancleSignModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import Sign from "../elements/Sign";

const CancleSignModal = () => {
  const [modal, setModal] = useRecoilState(cancleSignModalState);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>신용/체크카드 결제 취소</Title>
        {/*<Sign />*/}
        <ButtonArea>
          <Button styleType="line" onClick={handleClose}>
            취소
          </Button>
          <Button>다음</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CancleSignModal;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  & > button {
    max-width: calc(50% - 5px);
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  min-width: 284px;
  width: 284px;
  padding: 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
