import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

const TwoButtonModal = () => {
  const [modal, setModal] = useRecoilState(twoButtonModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handleCancle = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>{modal?.title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: modal?.text }} />
        <ButtonArea>
          <Button type="button" onClick={handleCancle}>
            {modal?.cancleText ? modal?.cancleText : "취소"}
          </Button>
          <span />
          <Button type="button" onClick={handleConfirm}>
            {modal?.confirmText ? modal?.confirmText : "확인"}
          </Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default TwoButtonModal;

const Button = styled.button`
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  color: ${(props) => props.theme.color.main};
  display: block;

  &:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span {
    min-width: 1px;
    width: 1px;
    height: 11px;
    margin: 0 20px;
    background: ${(props) => props.theme.color.gray4};
  }
`;

const Text = styled.div`
  margin: 10px 0 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: white;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: white;
`;

const Wrapper = styled.div`
  min-width: 335px;
  width: 335px;
  padding: 22px 20px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
