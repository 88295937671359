import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, cardSignModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

const AlertModal = () => {
  const [modal, setModal] = useRecoilState(alertModalState);
  const setSignModal = useSetRecoilState(cardSignModalState);

  /*useEffect(() => {
    if (modal?.title === "결제하기") {
      setTimeout(() => {
        setModal(null);
        if (modal?.price && modal?.price >= 50000)
          setSignModal({
            onDone: () => modal?.onDone && modal?.onDone(),
          });
        else modal?.onDone && modal?.onDone();
      }, 1000);
    }
  }, [modal]);*/

  const handleClose = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>{modal?.title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: modal?.text }} />
        <Button type="button" onClick={handleClose}>
          {modal?.confirmText ? modal?.confirmText : "취소"}
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default AlertModal;

const Button = styled.button`
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  color: ${(props) => props.theme.color.main};
  margin-left: auto;
  display: block;
`;

const Text = styled.div`
  margin: 10px 0 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: white;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: white;
`;

const Wrapper = styled.div`
  min-width: 335px;
  width: 335px;
  padding: 22px 20px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
