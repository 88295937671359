const color = {
  bg: "#19191B",
  main: "#73F7CA",
  red: "#f04c41",
  sub: "#20A97A",
  sub1: "#44D4A3",
  sub3: "#30BE8E",

  line: "#424243",

  gray1: "#0B0B0C",
  gray2: "#222225",
  gray3: "#303036",
  gray4: "#464650",
  gray5: "#6E6E77",
  gray6: "#888892",
};

export type ColorType = typeof color;

export default { color };
