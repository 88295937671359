import React from "react";

export const mergeList = (data: any) => data?.flatMap((d: any) => d);

export const toNumber = (value: string) => {
  let el = value.replace(/[^0-9]/g, "");
  return el;
};

export const Dot = (value: number) => {
  let el = (value ? value : 0).toString();
  return el.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const CardNumber = (value: number | string) => {
  let el = (value ? value : "0000000000000000").toString();
  el = el.replace(/\B(?=(\d{4})+(?!\d))/g, "-");
  let value2 = el.split("-");
  value2[2] = "****";

  return value2.join("-");
};
