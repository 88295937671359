import React, { useState } from "react";
import styled from "styled-components";
import { InputType } from "../../types/types";

const Input = ({
  placeholder,
  className,
  type = "text",
  value,
  disable = false,
  onChange,
  onFocus,
  ...props
}: InputType) => {
  return (
    <Label>
      <StyledInput
        type={type}
        inputMode="none"
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disable}
        onFocus={onFocus}
        {...props}
      />
    </Label>
  );
};

export default Input;

const StyledInput = styled.input`
  border-radius: 10px;
  padding: 11px 12px;
  width: 100%;
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: white;
  background: ${(props) => props.theme.color.gray3};

  &::placeholder {
    color: ${(props) => props.theme.color.gray5};
  }

  &:disabled {
    color: ${(props) => props.theme.color.gray6};
  }

  &.light {
    background: ${(props) => props.theme.color.gray4};
  }
`;

const Label = styled.label`
  width: 100%;
  position: relative;
`;
