import React from "react";
import styled, { css } from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  size?: "sm" | "md";
  disabled?: boolean;
  styleType?: "line" | "gray" | "green" | "red";
};

const Button = ({
  children,
  size = "md",
  disabled,
  styleType,
  ...props
}: ButtonProps) => {
  return (
    <Wrapper className={styleType} size={size} disabled={disabled} {...props}>
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button<{
  size: "sm" | "md";
  disabled?: boolean;
}>`
  //크기 중간
  width: 100%;
  height: 37px;

  //기본
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  border: solid 1px rgba(255, 255, 255, 0.9);

  &.line {
    border-color: white;
    background: none;
    color: white;

    &:disabled {
      color: ${(props) => props.theme.color.gray5};
      background: none;
      border-color: ${(props) => props.theme.color.gray4};
    }
  }

  &.gray {
    border-color: ${(props) => props.theme.color.gray3};
    background: ${(props) => props.theme.color.gray3};
    color: white;
  }

  &.green {
    border-color: ${(props) => props.theme.color.sub1};
    background: ${(props) => props.theme.color.sub1};
    color: ${(props) => props.theme.color.gray1};
  }

  &.red {
    border-color: ${(props) => props.theme.color.red};
    background: ${(props) => props.theme.color.red};
    color: white;
  }

  &:disabled {
    color: ${(props) => props.theme.color.gray4};
    background: ${(props) => props.theme.color.gray5};
    border-color: ${(props) => props.theme.color.gray5};
  }

  ${(props) => props.size === "sm" && css``}
`;
