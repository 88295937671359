import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { paymentDoneModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

const PaymentDoneModal = () => {
  const [modal, setModal] = useRecoilState(paymentDoneModalState);

  const handleClose = () => {
    modal?.onDone && modal?.onDone();
    setModal(null);
  };

  const handleConfirm = () => {
    window.rayno.receipt(localStorage.getItem("token"), modal?.orderId, 0);
    modal?.onDone && modal?.onDone();
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>{modal?.success ? '결제 완료' : '결제 실패'}</Title>
        <Text>
          {modal?.success ? (<>
            결제가 완료되었습니다.
            <br />
            고객님에게 영수증 출력 여부를 확인해주세요.
          </>) : (modal?.message)}
        </Text>
        <ButtonArea>
          <Button type="button" onClick={handleClose}>
            닫기
          </Button>
          {modal?.success && (<>
            <span />
            <Button type="button" onClick={handleConfirm}>
              영수증 출력
            </Button>
          </>)}
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PaymentDoneModal;

const Button = styled.button`
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  color: ${(props) => props.theme.color.main};
  display: block;

  &:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span {
    min-width: 1px;
    width: 1px;
    height: 11px;
    margin: 0 20px;
    background: ${(props) => props.theme.color.gray4};
  }
`;

const Text = styled.div`
  margin: 10px 0 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: white;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: white;
`;

const Wrapper = styled.div`
  min-width: 335px;
  width: 335px;
  padding: 22px 20px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
