import { atom } from "recoil";

type loadingModalType = {
  visabled: true;
} | null;

export const loadingModalState = atom<loadingModalType>({
  key: `loadingModalState`,
  default: null,
});

type alertModalType = {
  title: string;
  text: string;
  confirmText?: string;
  onConfirm?: () => void;
  onDone?: () => void;
  price?: number;
} | null;

export const alertModalState = atom<alertModalType>({
  key: `alertModalState`,
  default: null,
});

type TwoButtonModalType = {
  title: string;
  text: string;
  confirmText?: string;
  cancleText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const twoButtonModalState = atom<TwoButtonModalType>({
  key: `twoButtonModalState`,
  default: null,
});

type paymentDoneModalType = {
  success: boolean;
  message: string;
  orderId: string;
  onDone: () => void;
} | null;

export const paymentDoneModalState = atom<paymentDoneModalType>({
  key: `paymentDoneModalState`,
  default: null,
});

type paymentCancelModalType = {
  success: boolean;
  message: string;
  orderId: string;
  onDone: () => void;
} | null;

export const paymentCancelModalState = atom<paymentCancelModalType>({
  key: `paymentCancelModalState`,
  default: null,
});

type DiscountModalType = {
  total: number;
  setDiscount: (value: number) => void;
} | null;

export const DiscountModalState = atom<DiscountModalType>({
  key: `DiscountModalState`,
  default: null,
});

type PaymentModalType = {
  total: number;
  discount: number;
} | null;

export const PaymentModalState = atom<PaymentModalType>({
  key: `PaymentModalState`,
  default: null,
});

type cashBillModalType = {
  onDone: () => void;
} | null;

export const cashBillModalState = atom<cashBillModalType>({
  key: `cashBillModalState`,
  default: null,
});

type cashBillCancleModalType = {
  visabled: true;
} | null;

export const cashBillCancleModalState = atom<cashBillCancleModalType>({
  key: `cashBillCancleModalState`,
  default: null,
});

type cashModalType = {
  type: "plus" | "minus" | "complete";
  mutate: () => void;
} | null;

export const cashModalState = atom<cashModalType>({
  key: `cashModalState`,
  default: null,
});

type cardSignModalType = {
  onDone: () => void;
} | null;

export const cardSignModalState = atom<cardSignModalType>({
  key: `cardSignModalState`,
  default: null,
});

type cancleSignModalType = {
  visabled: true;
} | null;

export const cancleSignModalState = atom<cancleSignModalType>({
  key: `cancleSignModalState`,
  default: null,
});
