import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { DiscountModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";

import { ReactComponent as Remove } from "../../assets/icons/delete-icon.svg";
import { Dot } from "../../utils/CommonFunction";

const DiscountModal = () => {
  const [modal, setModal] = useRecoilState(DiscountModalState);

  const [dis, setDis] = useState<number>(0);
  const [value, setValue] = useState<string>("");
  const [unit, setUnit] = useState<"원" | "%">("원");

  useEffect(() => {
    if (modal?.total) {
      let n =
        unit === "원"
          ? Number(value)
          : Math.floor((modal?.total / 100) * Number(value));

      setDis(n < 0 ? 0 : n > modal?.total ? modal?.total : n);
    }
  }, [value, unit]);

  const handleClose = () => {
    setDis(0);
    setValue("");
    setUnit("원");
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.setDiscount && modal?.setDiscount(dis);
    setDis(0);
    setValue("");
    setUnit("원");
    setModal(null);
  };

  const handleDial = (number: number) => {
    setValue(value + number);
  };

  const handleRemove = () => {
    if (value.length > 0) setValue(value.slice(0, value.length - 1));
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <PreviewArea>
          <h1>할인</h1>
          <InputArea>
            <Input>{Dot(Number(value))}</Input>
            <Unit
              className={unit === "원" ? "active" : ""}
              onClick={() => setUnit("원")}
            >
              원
            </Unit>
            <Unit
              className={unit === "%" ? "active" : ""}
              onClick={() => setUnit("%")}
            >
              %
            </Unit>
          </InputArea>
          <Seperate />
          <LineArea>
            <Line>
              할인 적용 전
              <p>
                {Dot(modal?.total)}
                <span>원</span>
              </p>
            </Line>
            <Line>
              할인 금액
              <p>
                -{Dot(dis)}
                <span>원</span>
              </p>
            </Line>
            <Line>
              총 결제금액
              <p>
                {Dot(modal?.total - dis)}
                <span>원</span>
              </p>
            </Line>
          </LineArea>
          <ButtonArea>
            <Button styleType="line" onClick={handleClose}>
              취소
            </Button>
            <Button onClick={handleConfirm}>할인 적용</Button>
          </ButtonArea>
        </PreviewArea>
        <DialArea>
          <Dial onClick={() => handleDial(1)}>1</Dial>
          <Dial onClick={() => handleDial(2)}>2</Dial>
          <Dial onClick={() => handleDial(3)}>3</Dial>
          <Dial onClick={() => handleDial(4)}>4</Dial>
          <Dial onClick={() => handleDial(5)}>5</Dial>
          <Dial onClick={() => handleDial(6)}>6</Dial>
          <Dial onClick={() => handleDial(7)}>7</Dial>
          <Dial onClick={() => handleDial(8)}>8</Dial>
          <Dial onClick={() => handleDial(9)}>9</Dial>
          <Dial onClick={handleRemove}>
            <Remove />
          </Dial>
          <Dial onClick={() => handleDial(0)}>0</Dial>
        </DialArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default DiscountModal;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: white;

    & > span {
      margin-left: 2px;
      font-size: 10px;
      line-height: 15px;
    }
  }
`;

const LineArea = styled.div``;

const Seperate = styled.div`
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.line};
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  width: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: ${(props) => props.theme.color.gray5};
  margin-left: 8px;

  &.active {
    border-color: ${(props) => props.theme.color.sub1};
    background: ${(props) => props.theme.color.sub1};
    color: ${(props) => props.theme.color.gray1};
  }
`;

const Input = styled.div`
  width: calc(100% - 104px);
  height: 44px;
  border-radius: 10px;
  background: ${(props) => props.theme.color.gray4};
  padding: 8px 12px;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  color: white;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;

  & > button {
    max-width: calc(50% - 5px);
  }
`;

const PreviewArea = styled.div`
  min-width: 240px;
  width: 240px;

  & > h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: white;
    margin-bottom: 18px;
  }
`;

const Dial = styled.div`
  width: calc(33.33% - 6.66px);
  height: 50px;
  margin-right: 10px;
  border-radius: 10px;
  background: rgba(34, 34, 37, 0.4);
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.06em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 32px;
    height: 24px;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: 10px;
  }
`;

const DialArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  width: 200px;
  min-width: 200px;
  padding: 40px 0;
`;

const Wrapper = styled.div`
  min-width: 514px;
  width: 514px;
  padding: 0 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  display: flex;
  align-items: center;
`;
